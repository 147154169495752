import { getAxiosClientWithToken } from 'api';
import { handleFormData } from 'lib/utils';
import { CreateCreditCard } from '../types';

export const addCreditCard = (params: CreateCreditCard) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/credit-card/create`, formData);
};

export const getDetailCreditCard = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/credit-card/detail');
};

export const updateCreditCard = (params: CreateCreditCard) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/credit-card/update`, formData);
};
