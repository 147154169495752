import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { deleteUser, getUser, updateStatusUser } from 'api/user';
import { Constant } from 'config/constant';

export type DetailUserState = {
    error: boolean;
    loading: boolean;
    success: boolean;
    status: number;
    user: Detail;
};
export type Detail = {
    id: number;
    code: string;
    name: string;
    status: string;
    email: string;
    phone: string;
    created_at_format: string;
    last_login_format: string;
    places: Place[];
};

export type Place = {
    id: number;
    code: string;
    name: string;
    building_name: string;
    my_chargers: MyCharger[];
};
export type MyCharger = {
    code: string;
    id: number;
    my_charger_code: string;
    name: string;
};
export type idUser = {
    id?: number;
};
export type actionUser = {
    id?: number | string;
    action: string;
};

export const fetchUser = createAsyncThunk('admin/user/:id', async (id: string | number, { dispatch, rejectWithValue }) => {
    try {
        const response = await getUser(id);
        const { data, success } = response.data;

        if (success) {
            dispatch(setDetailUser({ user: data }));
            return true;
        }
    } catch (error: any) {
        dispatch(setDetailUser(error));
        return rejectWithValue(error);
    }
});
export const actionUser = createAsyncThunk('admin/user/:id/', async (actionUser: any, { dispatch, rejectWithValue }) => {
    try {
        const response = await updateStatusUser(actionUser);
        const { data, success } = response.data;

        if (success) {
            dispatch(setUpdateUser(response.data));
            return true;
        }
    } catch (error: any) {
        dispatch(setUpdateUser(error));
        return rejectWithValue(error);
    }
});
export const destroyUser = createAsyncThunk('admin/user/:id/delete', async (id: string | number, { dispatch, rejectWithValue }) => {
    try {
        const response = await deleteUser(id);
        dispatch(setDeleteUser(response));
        return true;
    } catch (error: any) {
        dispatch(setDeleteUser(error));
        return rejectWithValue(error);
    }
});

export const detailUserSlice = createSlice({
    name: 'detailUser',
    initialState: {
        error: false,
        loading: false,
        success: false,
        status: Constant.DEFAULT_STATUS,
        user: {},
    } as DetailUserState,
    reducers: {
        resetStatusState: (state: DetailUserState) => {
            state.status = Constant.DEFAULT_STATUS;
            state.error = false;
            state.loading = false;
            state.success = false;
        },
        setDetailUser: (state: DetailUserState, { payload }) => {
            const { user } = payload;
            state.user = user;
            state.status = payload?.response?.status;
        },
        setUpdateUser: (state: DetailUserState, { payload }) => {
            state.status = payload?.code;
        },
        setDeleteUser: (state: DetailUserState, { payload }) => {
            const { user } = payload;
            state.user = user;
            state.status = payload?.response?.status;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state: DetailUserState) => {
                state.loading = true;
            })
            .addCase(fetchUser.rejected, (state: DetailUserState) => {
                state.loading = false;
                state.success = false;
                state.error = true;
            })
            .addCase(fetchUser.fulfilled, (state: DetailUserState) => {
                state.loading = false;
                state.success = true;
                state.error = false;
            })
            .addCase(actionUser.pending, (state: DetailUserState) => {
                state.loading = true;
            })
            .addCase(actionUser.rejected, (state: DetailUserState) => {
                state.loading = false;
                state.success = false;
                state.error = true;
            })
            .addCase(actionUser.fulfilled, (state: DetailUserState) => {
                state.loading = false;
                state.success = true;
                state.error = false;
            })
            .addCase(destroyUser.pending, (state: DetailUserState) => {
                state.loading = true;
            })
            .addCase(destroyUser.rejected, (state: DetailUserState) => {
                state.loading = false;
                state.success = false;
                state.error = true;
            })
            .addCase(destroyUser.fulfilled, (state: DetailUserState) => {
                state.loading = false;
                state.success = true;
                state.error = false;
            });
    },
});
export const userSelector = (state: RootState) => state.users;
export const { setDetailUser, resetStatusState, setUpdateUser, setDeleteUser } = detailUserSlice.actions;
