import { getAxiosClientWithToken } from 'api';
import { handleFormData } from 'lib/utils';
import {
    BookingList,
    CancelBooking,
    ChargerModelParams,
    ErrorsStatusParams,
    ListChargerRequest,
    ListPlaceRequest,
    ChargingHistory,
    SearchPlaceRequest,
    UpdateMasterSlave,
    ListStatisticRequestByCharger,
    NittoChargerStatus,
    SendConnectNitto,
} from 'redux/slice/chargeSlice';
import { CreateChargerData, CSVexport, UpdateStatusCharger } from 'types';

export const getListCharger = (params: ListChargerRequest) => {
    return getAxiosClientWithToken().get('admin/charger', { params });
};

export const createCharger = (params: CreateChargerData) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/charger/store`, formData);
};

export const editCharger = (params: { data: CreateChargerData; id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params.data);
    return restClient.post(`/admin/charger/${params.id}/update`, formData);
};

export const detailCharger = (params: { id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/charger/${params.id}`);
};

export const getSearchPlaceInListCharger = (params: SearchPlaceRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/place/search', { params });
};

export const getChargerOperationStatus = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/charger-operation-status');
};
export const getChargerType = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/charger-type');
};
export const getChargerMakerType = (params: { id?: Number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/charger-maker/${params.id}/charger-type`);
};

export const getChargerStatus = (params: { type?: number }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/charger-status', { params });
};

export const getChargerMaker = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/charger-maker');
};

export const listByPlaceInCharger = (params: ListPlaceRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/place/list-place-selected', { params });
};

export const exportCSV = (params: CSVexport) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/charging-history/export', { params });
};

export const exportChargerHistoryCSV = (params: CSVexport, id?: string | number) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/charger/${id}/export`, { params });
};

export const getListBooking = (params: BookingList) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/booking', { params });
};

export const cancelBookingReservationList = (params: CancelBooking) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/booking/${params.id}/cancel`, formData);
};

export const updateMasterSlaveFlagCharger = (params: UpdateMasterSlave) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/charger/${params.id}/update-master-slave-flag`, formData);
};

export const getListErrorsStatus = (params: ErrorsStatusParams) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/error-status', { params });
};

export const getListErrorsLog = (params: { day_search?: string; error_status_id?: string | number }, id?: number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/charger/${id}/error-log`, { params });
};

export const updateStatusCharger = (params: { data: UpdateStatusCharger; id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params.data);
    return restClient.post(`/admin/charger/${params.id}/update-status`, formData);
};

export const getChargerModel = (params: ChargerModelParams) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('admin/charger-model', { params });
};

export const getItemPlaceStatistic = (params: { id?: Number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/place/${params.id}/statistic`);
};

export const fetchChargingHistory = (params: ChargingHistory) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('admin/charging-history', { params });
};

export const fetchChargingHistoryByCharger = (params: ChargingHistory, id: number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/charger/${id}/charging-history`, { params });
};

export const getStatisticHistoryByCharger = (params: ListStatisticRequestByCharger, id: number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/charger/${id}/statistic-charging-history`, { params });
};

export const stopCharger = (id: number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`admin/charger/${id}/stop`);
};

export const openCharger = (id: number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`admin/charger/${id}/open`);
};

export const connectNitto = (params: { data: SendConnectNitto; id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params.data);
    return restClient.post(`admin/charger/${params.id}/connect-nitto`, formData);
};

export const getStatusNitto = ( id: number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/charger/${id}/status-nitto`);
};

export const getListStatusNitto = (params: { id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/charger/${params.id}/list-status-nitto`, { params });
};

export const getListSupplyStatus = (params: { id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/charger/${params.id}/list-supply-status`, { params });
};

export const startNitto = (params: NittoChargerStatus, id: number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`admin/charger/${id}/start-charging`, params);
};

export const stopNitto = (id: number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`admin/charger/${id}/stop-charging`);
};

export const getInfoNitto = (params: { id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/charger/${params.id}/info-nitto`, { params });
};
