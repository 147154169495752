import React from 'react';
import { Breadcrumb as BreadCrumbs } from 'antd';
import { Link } from 'react-router-dom';

type Props = {
    breadcrumb: Breadcrumb[];
    style?: React.CSSProperties;
};

export type Breadcrumb = {
    name: string;
    url: string;
};

/**
 * Creates Breadcrumb
 * @param { Props } props
 * @return BreadCrumbs
 * */
const Breadcrumbs = (props: Props) => {
    const { breadcrumb } = props;

    return (
        <div className='breadcrumb-container'>
            <BreadCrumbs separator=' > '>
                {breadcrumb.map((breadCrumbProps) => (
                    <BreadCrumbs.Item key={breadCrumbProps.url}>
                        {breadCrumbProps.url ? (
                            <Link to={breadCrumbProps.url}>{breadCrumbProps.name}</Link>
                        ) : (
                            <span>{breadCrumbProps.name}</span>
                        )}
                    </BreadCrumbs.Item>
                ))}
            </BreadCrumbs>
        </div>
    );
};

export default Breadcrumbs;
