import { getAxiosClientWithToken } from 'api';
import { handleFormData } from 'lib/utils';
import { CreateInvoiceParams, ListInvoiceRequest } from 'redux/slice/invoiceSlice';

export const createInvoice = (params: CreateInvoiceParams) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/invoice/create`, formData);
};

export const getPreviewInvoice = (params: { billing_time_start: string; billing_time_end: string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/invoice/preview-in`, { params });
};

export const getDetailInvoice = (params: { id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/invoice/${params.id}`);
};

export const getListInvoice = (params: ListInvoiceRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/invoice`, { params });
};

export const getStatisticInvoice = (params: ListInvoiceRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/invoice/statistic`, { params });
};

export const getExportInvoice = (params: { id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/invoice/${params.id}/export`, { responseType: 'arraybuffer' });
};
