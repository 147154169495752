import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from 'api';
import {
    deleteSection,
    getChartData,
    getDetailBookingCancel,
    getDetailChargerHistory,
    getListCityByPrefecture,
    getPrefectureChargerHistory,
    getStatisticHistory,
} from 'api/chargerHistory';
import { RootState } from '../store';
import { Constant } from 'config/constant';
import { ChargerBooking } from 'types';

export type DetailChargerHistory = {
    id: number;
    place_id: number;
    place_code: string;
    place: string;
    place_address: string;
    building_name: string;
    prefecture: string;
    city: string;
    user_id: number;
    user_code: string;
    user_first_name: string;
    user_last_name: string;
    user_name: string;
    charger_id: number;
    charger_code: string;
    charger_type_id: number;
    charger_type: string;
    charger_type_icon_url: string;
    charging_status_id: number;
    charging_status: string;
    charging_date_start_jp: string;
    charging_time_start: string;
    charging_date_end_jp: string;
    charging_time_end: string;
    charging_time_start_jp: string;
    charging_time_end_jp: string;
    charging_time: number;
    charging_amount: number;
    electricity_fee: number;
    power_used: number;
    electric_bill: number;
    charging_bill: number;
    charging_fee: number;
    error_log?: string;
    booking_status_id: number;
    charging_method: number;
};

export type Prefecture = {
    code: string;
    name_kana: string;
    name: string;
};

export type City = {
    prefecture_code: string;
    city_code: string;
    city_name_kana: string;
    city_name: string;
};

export type ChartCount = {
    totalCharging: ChargingTime[];
    totalChargingTime: TotalCharging[];
};

export type ChargingTime = {
    total_charging_time: number;
    date: string;
};

export type TotalCharging = {
    total_charging: number;
    date: string;
};

export type ChartData = {
    total_charging_bill: number;
    total_electric_bill: number;
    total_charging: number;
    total_charging_time: number;
    date: string;

}

export type ChargingBill = {
    total_charging_bill: number;
    date: string;
};

export type ElectricBill = {
    total_electric_bill: number;
    date: string;
};

export type ListStatisticHistoryRequest = {
    place_id?: number[];
    place_code?: number | string;
    start_time?: string;
    end_time?: string;
    charging_tab?: string | number;
};

export type ChargerHistoryData = {
    status: number;
    detailChargerHistory: {
        success: boolean;
        message: string;
        chargerHistory: DetailChargerHistory;
        loading: boolean;
        error: boolean;
        status: number;
        delete_available: boolean;
    };
    deleteSection: {
        success: boolean;
        error: boolean;
        messages: string;
        loading: boolean;
    };
    prefecture: {
        success: boolean;
        message: string;
        data: Prefecture[];
        loading: boolean;
        error: boolean;
        status: number;
    };
    cityByPrefecture: {
        success: boolean;
        message: string;
        data: City[];
        loading: boolean;
        error: boolean;
    };
    chartData: {
        success: boolean;
        message: string;
        data: ChartData[];
        loading: boolean;
        error: boolean;
    };
    chargerBooking: {
        success: boolean;
        message: string;
        data: ChargerBooking;
        loading: boolean;
        error: boolean;
    };
};

export const getDetailChargerHistoryData = createAsyncThunk(
    'admin/charging-history/:id',
    async (params: { id: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await getDetailChargerHistory(params);
            const { success } = response.data;
            if (success) {
                dispatch(setDetailChargerHistory(response.data));
                return true;
            }
            return rejectWithValue(false);
        } catch (error: any) {
            dispatch(setDetailChargerHistory(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const getDetailBookingCancelData = createAsyncThunk(
    'admin/booking/:id',
    async (params: { id: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await getDetailBookingCancel(params);
            const { success } = response.data;
            if (success) {
                dispatch(setDetailBookingCancel(response.data));
                return true;
            }
            return rejectWithValue(false);
        } catch (error: any) {
            dispatch(setDetailBookingCancel(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const getPrefectureData = createAsyncThunk('prefecture', async (_, { dispatch, rejectWithValue }) => {
    try {
        let response = await getPrefectureChargerHistory();
        const { success } = response.data;
        if (success) {
            dispatch(setPrefectureChargerHistory(response.data));
            return true;
        }
        return rejectWithValue(false);
    } catch (error: any) {
        dispatch(setPrefectureChargerHistory(error));
        return rejectWithValue(getErrorMessage(error));
    }
});

export const getListCityByPrefectureData = createAsyncThunk(
    'prefecture/city',
    async (params: { prefecture_code: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await getListCityByPrefecture(params);
            const { success } = response.data;
            if (success) {
                dispatch(setCityByPrefecture(response.data));
                return true;
            }
            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const destroySection = createAsyncThunk(
    '/admin/charging-history/:id/delete',
    async (id: number | string, { dispatch, rejectWithValue }) => {
        try {
            let response = await deleteSection(id);
            return response;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchDataChart = createAsyncThunk(
    'admin/charging-history/data-chart',
    async (params: { start_time?: string; end_time?: string; charger_id?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await getChartData(params);
            dispatch(setChartData(response.data));
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);


export const fetchHistoryStatistic = createAsyncThunk(
    'admin/charging-history/statistic',
    async (params: ListStatisticHistoryRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await getStatisticHistory(params);
            dispatch(setListHistoryStatistic(response.data));
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const chargerHistorySlice = createSlice({
    name: 'chargerHistory',
    initialState: {
        detailChargerHistory: {
            success: false,
            message: '',
            chargerHistory: {} as DetailChargerHistory,
            loading: false,
            error: false,
            delete_available: false,
        },
        prefecture: {
            success: false,
            message: '',
            data: [] as Prefecture[],
            loading: false,
            error: false,
        },
        cityByPrefecture: {
            success: false,
            message: '',
            data: [] as City[],
            loading: false,
            error: false,
        },
        deleteSection: {
            success: false,
            error: false,
            messages: '',
            loading: false,
        },
        chartData: {
            success: false,
            message: '',
            data: [] as ChartData[],
            loading: false,
            error: false,
        },
        chargerBooking: {
            success: false,
            message: '',
            data: { charger_history: {}, booking_reserved: {} } as ChargerBooking,
            loading: false,
            error: false,
        },
    } as ChargerHistoryData,
    reducers: {
        resetDetailHistoryState: (state: ChargerHistoryData) => {
            state.detailChargerHistory.status = Constant.DEFAULT_STATUS;
            state.detailChargerHistory = {
                loading: false,
                success: false,
                error: false,
                message: '',
                status: 0,
                chargerHistory: {} as DetailChargerHistory,
                delete_available: false,
            };
        },
        resetDeleteSectionState: (state: ChargerHistoryData) => {
            state.status = Constant.DEFAULT_STATUS;
            state.deleteSection = {
                loading: false,
                success: false,
                error: false,
                messages: '',
            };
        },
        setDetailChargerHistory: (state: ChargerHistoryData, { payload }) => {
            state.detailChargerHistory.chargerHistory = payload?.data;
            state.detailChargerHistory.success = payload?.success;
            state.detailChargerHistory.message = payload?.message;
            state.detailChargerHistory.status = payload?.response?.status;
            state.detailChargerHistory.delete_available = payload?.delete_available;
        },
        setDetailBookingCancel: (state: ChargerHistoryData, { payload }) => {
            state.detailChargerHistory.chargerHistory = payload?.data;
            state.detailChargerHistory.success = payload?.success;
            state.detailChargerHistory.message = payload?.message;
            state.detailChargerHistory.status = payload?.response?.status;
        },
        setPrefectureChargerHistory: (state: ChargerHistoryData, { payload }) => {
            state.prefecture.data = payload?.data;
            state.prefecture.success = payload?.success;
            state.prefecture.message = payload?.message;
            state.prefecture.status = payload?.response?.status;
        },
        setCityByPrefecture: (state: ChargerHistoryData, { payload }) => {
            state.cityByPrefecture.data = payload?.data;
            state.cityByPrefecture.success = payload?.success;
            state.cityByPrefecture.message = payload?.message;
        },
        setChartData: (state: ChargerHistoryData, { payload }) => {
            state.chartData.data = payload?.data;
            state.chartData.success = payload?.success;
            state.chartData.message = payload?.message;
        },
        setChartCount: (state: ChargerHistoryData, { payload }) => {
            state.chartData.data = payload?.data;
            state.chartData.success = payload?.success;
            state.chartData.message = payload?.message;
        },
        setListHistoryStatistic: (state: ChargerHistoryData, { payload }) => {
            state.chargerBooking.data = payload?.data;
            state.chargerBooking.success = payload?.success;
            state.chargerBooking.message = payload?.message;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDetailChargerHistoryData.pending, (state: ChargerHistoryData) => {
                state.detailChargerHistory.loading = true;
            })
            .addCase(getDetailChargerHistoryData.rejected, (state: ChargerHistoryData, { payload }) => {
                state.detailChargerHistory.loading = false;
                state.detailChargerHistory.success = false;
                state.detailChargerHistory.error = true;
                state.detailChargerHistory.message = payload as string;
            })
            .addCase(getDetailChargerHistoryData.fulfilled, (state: ChargerHistoryData, action) => {
                state.detailChargerHistory.loading = false;
                state.detailChargerHistory.success = true;
                state.detailChargerHistory.error = false;
            })
            .addCase(getDetailBookingCancelData.pending, (state: ChargerHistoryData) => {
                state.detailChargerHistory.loading = true;
            })
            .addCase(getDetailBookingCancelData.rejected, (state: ChargerHistoryData, { payload }) => {
                state.detailChargerHistory.loading = false;
                state.detailChargerHistory.success = false;
                state.detailChargerHistory.error = true;
                state.detailChargerHistory.message = payload as string;
            })
            .addCase(getDetailBookingCancelData.fulfilled, (state: ChargerHistoryData, action) => {
                state.detailChargerHistory.loading = false;
                state.detailChargerHistory.success = true;
                state.detailChargerHistory.error = false;
            })
            .addCase(getPrefectureData.pending, (state: ChargerHistoryData) => {
                state.prefecture.loading = true;
            })
            .addCase(getPrefectureData.rejected, (state: ChargerHistoryData, { payload }) => {
                state.prefecture.loading = false;
                state.prefecture.success = false;
                state.prefecture.error = true;
                state.detailChargerHistory.message = payload as string;
            })
            .addCase(getPrefectureData.fulfilled, (state: ChargerHistoryData, action) => {
                state.prefecture.loading = false;
                state.prefecture.success = true;
                state.prefecture.error = false;
            })
            .addCase(getListCityByPrefectureData.pending, (state: ChargerHistoryData) => {
                state.cityByPrefecture.loading = true;
            })
            .addCase(getListCityByPrefectureData.rejected, (state: ChargerHistoryData, { payload }) => {
                state.cityByPrefecture.loading = false;
                state.cityByPrefecture.success = false;
                state.cityByPrefecture.error = true;
                state.cityByPrefecture.message = payload as string;
            })
            .addCase(getListCityByPrefectureData.fulfilled, (state: ChargerHistoryData, action) => {
                state.cityByPrefecture.loading = false;
                state.cityByPrefecture.success = true;
                state.cityByPrefecture.error = false;
            })
            .addCase(destroySection.pending, (state: ChargerHistoryData) => {
                state.deleteSection = {
                    loading: true,
                    success: false,
                    error: false,
                    messages: '',
                };
            })
            .addCase(destroySection.rejected, (state: ChargerHistoryData, { payload }) => {
                state.deleteSection = {
                    loading: false,
                    success: false,
                    error: true,
                    messages: 'error',
                };
            })
            .addCase(destroySection.fulfilled, (state: ChargerHistoryData) => {
                state.deleteSection = {
                    loading: false,
                    success: true,
                    error: false,
                    messages: '',
                };
            })
            .addCase(fetchDataChart.pending, (state: ChargerHistoryData) => {
                state.chartData.loading = true;
            })
            .addCase(fetchDataChart.rejected, (state: ChargerHistoryData, { payload }) => {
                state.chartData.loading = false;
                state.chartData.success = false;
                state.chartData.error = true;
            })
            .addCase(fetchDataChart.fulfilled, (state: ChargerHistoryData, action) => {
                state.chartData.loading = false;
                state.chartData.success = true;
                state.chartData.error = false;
            })
            .addCase(fetchHistoryStatistic.pending, (state: ChargerHistoryData) => {
                state.chargerBooking.loading = true;
            })
            .addCase(fetchHistoryStatistic.rejected, (state: ChargerHistoryData, { payload }) => {
                state.chargerBooking.loading = false;
                state.chargerBooking.success = false;
                state.chargerBooking.error = true;
            })
            .addCase(fetchHistoryStatistic.fulfilled, (state: ChargerHistoryData, action) => {
                state.chargerBooking.loading = false;
                state.chargerBooking.success = true;
                state.chargerBooking.error = false;
            });
    },
});

export const {
    setDetailChargerHistory,
    resetDetailHistoryState,
    setPrefectureChargerHistory,
    setCityByPrefecture,
    resetDeleteSectionState,
    setChartData,
    setDetailBookingCancel,
    setListHistoryStatistic,
} = chargerHistorySlice.actions;
export const ChargerHistorySelector = (state: RootState) => state.chargerHistory;
