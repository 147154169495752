import { Button, Col, Modal, Row, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { reset, setSuccessState, SuccessModalState } from 'redux/slice/successSlice';
import checkIcon from 'assets/images/success_check.png';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Constant } from 'config/constant';
import { resetStatusState, userSelector } from 'redux/slice/detailUserSlice';
import {  useRedirectStatusError } from 'lib/utils';
import { selectAuth } from 'redux/slice/authSlice';
import {fetchOpenPlace, fetchStopPlace, placeSelector, resetOpenPlace, resetStopPlace} from "redux/slice/placeSlice";

interface Data {
    place: any | null;
    title: string | null;
    nameBtn: string | null;
    message: string | null;
    keyword: string | undefined;
    page: number | undefined;
    currentPlace():void;
}

function Confirm(props?: Data) {
    const { role } = useAppSelector(selectAuth);
    const dispatch = useAppDispatch();
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [msg, setMsg] = useState<string>();
    const [paramSearch, setParamSearch] = useState<any>({});
    const { status, stopPlace, openPlace } = useAppSelector(placeSelector);
    const showModal = () => {
        setParamSearch(props?.keyword);
        setVisible(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        if (!status) {
            switch (props?.nameBtn) {
                case Constant.CHARGER_OPEN:
                    setMsg('施設の営業を停止しました。');
                    dispatch(
                        fetchStopPlace({
                            id: props?.place?.id,
                        })
                    );
                    break;
                case Constant.CHARGER_STOP:
                    setMsg('施設の営業を再開しました。');
                    dispatch(
                        fetchOpenPlace({
                            id: props?.place?.id,
                        })
                    );
                    break;
                default:
                    break;
            }
            setVisible(false);
            setConfirmLoading(false);
            setUpdateSuccess(true);
        } else {
            dispatch(resetStopPlace());
            dispatch(resetOpenPlace());
            setConfirmLoading(false);
        }
    };
    const handleCancel = () => {
        setVisible(false);
    };
    const onCancelSuccess = useCallback(() => {
        setUpdateSuccess(false);
        props?.currentPlace()
        dispatch(reset());
    }, [dispatch, props]);
    const renderAction = () => {
        return (
            <Row justify={'center'}>
                {props?.nameBtn === Constant.CHARGER_OPEN ? (
                    <Button onClick={showModal} className='btn-success btn text-center' style={{minWidth:"75px"}} type='primary'>
                        {props?.nameBtn}
                    </Button>
                ) : (
                    <Button onClick={showModal} danger className='stop-btn p-0 w-55px' style={{minWidth:"75px"}}>
                        {props?.nameBtn}
                    </Button>
                )
                }
            </Row>
        );
    };
    const renderBtn = () => {
        return (
            <Row justify={'center'}>
                {props?.nameBtn === Constant.CHARGER_OPEN ? (
                    <Row aria-colspan={10} gutter={8} className='mt-3 mb-3' justify={'center'}>
                        <Col>
                            <Button className='btn-modal stop-btn' danger onClick={handleCancel}>
                                キャンセル
                            </Button>
                        </Col>
                        <Col>
                            <Button className='btn-success btn btn-modal' type="primary" onClick={handleOk}>
                                {Constant.CONFIRM_STOP}
                            </Button>
                        </Col>
                    </Row>
                ) : (
                    <Row aria-colspan={10} gutter={8} className='mt-3 mb-3' justify={'center'}>
                        <Col>
                            <Button className='btn-modal stop-btn' danger onClick={handleCancel}>
                                キャンセル
                            </Button>
                        </Col>
                        <Col>
                            <Button className='btn-success btn btn-modal' type="primary" onClick={handleOk}>
                                {Constant.CONFIRM_ON}
                            </Button>
                        </Col>
                    </Row>
                )}
            </Row>
        );
    };
    useEffect(() => {
        if ((stopPlace.success || openPlace.success) && msg) {
            const successModalPayload: SuccessModalState = {
                isShow: true,
                title: props?.title as string,
                message: <div className='message'>{msg}</div>,
                withButton: true,
                image: checkIcon,
                centered: true,
                onCancel: () => onCancelSuccess(),
                modalClassname: 'registration-success',
                buttonTitle: 'OK',
            };
            dispatch(setSuccessState(successModalPayload));
            dispatch(resetStopPlace());
            dispatch(resetOpenPlace());
        }
    }, [dispatch, stopPlace.success, openPlace.success]);
    useRedirectStatusError(status, resetStopPlace());
    useRedirectStatusError(status, resetOpenPlace());
    return (
        <>
            {renderAction()}
            <Modal title={props?.title} visible={visible} footer={false} closable={false} centered transitionName=''>
                <Spin spinning={confirmLoading}>
                    <div className='align-center'>
                        <p>{props?.message}</p>
                        <p>施設名 : {props?.place?.name}</p>
                        <p>施設ID : {props?.place?.code}</p>
                    </div>
                    {renderBtn()}
                </Spin>
            </Modal>
        </>
    );
}

export default Confirm;
