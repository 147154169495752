import { getAxiosClientWithToken } from './index';
import { UpdatePassword, CreateAccountData, ResetPassword, sendEmailPassword } from 'redux/slice/accountSlice';
import { handleFormData } from 'lib/utils';

export const getListAdmin = (page?: number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/accounts`, { params: { page } });
};

export const createAdmin = (params: CreateAccountData) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/accounts/store`, formData);
};

export const changePassword = (params: UpdatePassword) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/change-password`, formData);
};

export const sendEmail = (params: sendEmailPassword) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/forgot-password`, formData);
};

export const forgotPassword = (params: ResetPassword) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/reset-password`, formData);
};
