import { getAxiosClientWithToken } from 'api';
import { CategoryData } from 'redux/slice/categorySlice';
import { handleFormData } from 'lib/utils';

export const getCategoryList = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/category');
};

export const getCategory = (params: { id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/category/${params.id}`);
};

export const updateCategory = (params: CategoryData) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/category/${params.id}/update`, formData);
};
