import { getAxiosClientWithToken } from 'api';
import { handleFormData } from 'lib/utils';
import { ListNotificationRequest } from 'redux/slice/notificationSlice';
import { CreateNotificationData, NotificationData } from 'types';

export const createNotification = (params: CreateNotificationData) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/notification/create`, formData);
};

export const getListNotification = (params: ListNotificationRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/notification', { params });
};

export const updateNotification = (params: { data: NotificationData; id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params.data);
    return restClient.post(`/admin/notification/${params.id}/update`, formData);
};

export const pushNotification = (params: {id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/notification/${params.id}/push-now`, formData);
};

export const getDetailNotification = (params: { id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/notification/${params.id}`);
};

export const deleteNotification = (id: string | number) => {
    return getAxiosClientWithToken().delete(`admin/notification/${id}/delete`);
};

