import React from 'react';
import {Layout, Spin} from "antd";
import classNames from "classnames";

type Props = {
    force?: boolean;
};

const Loader = ({ force }: Props) => {
    return (
        <Layout.Content className={classNames('h-100 w-100 position-fixed top-0 bottom-0 left-0 right-0 d-flex justify-content-center align-items-center', {'d-none': !force})}>
            <Spin spinning size="large"/>
        </Layout.Content>
    );
};

export default Loader;
