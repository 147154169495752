import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { ADMIN, SIDEBAR_WIDTH, SUPER_ADMIN } from 'config/constant';
import { Image, Layout, Menu, SiderProps } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { isValidRoles, useIsActiveMenu, useIsMobile, useSelectedMenu } from 'lib/utils';
import UserIcon from 'assets/images/ic-user.svg';
import type { MenuProps } from 'antd';
import SettingIcon from 'assets/images/setting-icon.svg';
import PlaceIcon from 'assets/images/place-icon.svg';
import ChargeHistory from 'assets/images/ic-charger-history.svg';
import HomeIcon from 'assets/images/sidebar/home.svg';
import MapIcon from 'assets/images/sidebar/map.svg';
import { useAppSelector } from 'redux/hooks';
import { selectAuth } from 'redux/slice/authSlice';
import NotificationIcon from 'assets/images/ic-notification.svg';

const { Sider } = Layout;

type Props = SiderProps & {
    onCloseCollapsed?: () => void;
    setIsCollapsed: Dispatch<SetStateAction<boolean>>;
};

const Sidebar = (props: Props) => {
    const { breakpoint, collapsedWidth, onBreakpoint, collapsed, onCollapse, onCloseCollapsed, setIsCollapsed } = props;
    const { pathname } = useLocation();
    const [openKeys, setOpenKeys] = useIsActiveMenu();
    const selectedMenus = useSelectedMenu();
    const navigate = useNavigate();
    const { role } = useAppSelector(selectAuth);
    const isMobile = useIsMobile();

    const onOpenChange = (keys: string[]) => {
        let newOpenKeys = [];
        let lastKey = keys.pop();
        if (lastKey) {
            if (lastKey === 'setting-notifications') {
                newOpenKeys.push(...keys);
            }
            newOpenKeys.push(lastKey);
        }
        setOpenKeys(newOpenKeys);
    };

    const menuItems: MenuProps['items'] = [
        {
            key: 'home',
            icon: <Image src={HomeIcon} width={20} preview={false} />,
            className: 'my-0 site-menu-item',
            label: `${collapsed ? '' : 'トップ画面'}`,
        },
        {
            key: 'map',
            icon: <Image src={MapIcon} width={20} preview={false} />,
            className: 'my-0 site-menu-item',
            label: `${collapsed ? '' : 'MAP'}`,
        },
        {
            key: 'places',
            icon: <Image src={PlaceIcon} width={20} preview={false} />,
            className: 'my-0 site-menu-item',
            label: `${collapsed ? '' : '施設管理'}`,
        },
        {
            key: 'users',
            icon: <Image src={UserIcon} width={20} preview={false} />,
            className: 'my-0 site-menu-item',
            label: `${collapsed ? '' : 'ユーザー管理'}`,
        },
        {
            key: 'chargers-history',
            icon: <Image src={ChargeHistory} width={20} preview={false} />,
            className: 'my-0  site-menu-item',
            label: `${collapsed ? '' : '充電予約・履歴管理'}`,
            theme: 'light',
        },
        {
            key: 'settings',
            icon: <Image src={SettingIcon} width={20} preview={false} />,
            className: 'my-0 site-menu-item',
            label: `${collapsed ? '' : '管理者設定'}`,
            theme: 'light',
            children: [
                {
                    key: 'change-password',
                    className: 'my-0 site-menu-item',
                    label: 'パスワード変更',
                },
                {
                    key: 'invoice',
                    className: 'my-0 site-menu-item',
                    label: '請求書確認',
                },
                isValidRoles(role, [ADMIN])
                    ? {
                          key: 'accounts',
                          className: 'my-0 site-menu-item',
                          label: '施設アカウント一覧・登録',
                      }
                    : null,
                isValidRoles(role, [SUPER_ADMIN])
                    ? {
                        key: 'credit-card',
                        className: 'my-0 site-menu-item',
                        // label: '請求発行',
                    label: 'クレジットカード情報',
                    }
                    : null,
            ],
        },
        {
            key: 'setting-notifications',
            icon: <Image src={NotificationIcon} width={20} preview={false} />,
            className: 'my-0 site-menu-item',
            label: `${collapsed ? '' : 'お知らせ'}`,
            theme: 'light',
            children: [
                isValidRoles(role, [SUPER_ADMIN, ADMIN])
                    ? {
                          key: 'notification',
                          className: 'my-0 site-menu-item',
                          label: 'お知らせ管理',
                      }
                    : null,
                isValidRoles(role, [SUPER_ADMIN])
                    ? {
                          key: 'category',
                          className: 'my-0 site-menu-item',
                          label: 'カテゴリー管理',
                      }
                    : null,
            ],
        },
    ];

    const onMenuClick: MenuProps['onClick'] = ({ key }) => {
        switch (key) {
            case 'home':
                navigate('');
                break;
            case 'map':
                navigate('/map');
                break;
            case 'chargers':
                navigate('/chargers');
                break;
            case 'users':
                navigate('/users');
                break;
            // case 'chargers-management':
            //     navigate('/places/chargers-management');
            //     break;
            case 'chargers-history':
                navigate('/chargers-history');
                break;
            case 'change-password':
                navigate('/change-password');
                break;
            case 'credit-card':
                navigate('/credit-card');
                break;
            case 'places':
                navigate('/places');
                break;
            case 'accounts':
                navigate('/accounts');
                break;
            case 'notification':
                navigate('/notification');
                break;
            case 'category':
                navigate('/category');
                break;
            case 'invoice':
                navigate('/invoice');
                break;
            default:
                break;
        }
    };
    const redirectHome = () => {
        navigate('/');
    };
    useEffect(() => {
        onCloseCollapsed?.();
    }, [onCloseCollapsed, pathname]);

    return (
        <Sider
            breakpoint={breakpoint}
            collapsedWidth={collapsedWidth}
            onBreakpoint={onBreakpoint}
            onCollapse={onCollapse}
            collapsed={collapsed}
            width={SIDEBAR_WIDTH}
            className='sidebar-collapsed-toggle position-fixed top-0 bottom-0 start-0 site-sidebar ant-menu-light'
            style={{ transition: '0.4s' }}
            onMouseEnter={() => setIsCollapsed(false)}
            onMouseLeave={() => setIsCollapsed(true)}
        >
            {/*<Header className='site-sidebar-header text-nowrap px-4 text-center'>*/}
            {/*    <Image src={Logo} preview={false} onClick={redirectHome} />*/}
            {/*</Header>*/}
            {/* {!isMobile && <Image src={Hamburger} className='menuSidebar' onClick={() => setIsCollapsed(!collapsed)} preview={false} />} */}
            <Menu
                key={pathname}
                defaultSelectedKeys={selectedMenus}
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                theme='dark'
                mode='inline'
                className='position-relative overflow-auto ant-sidebar-nav'
                items={menuItems}
                onClick={onMenuClick}
            />
        </Sider>
    );
};

export default Sidebar;
