import { getAxiosClientWithToken } from './index';
import { ListStatisticHistoryRequest } from 'redux/slice/ChargerHistorySlice';

export const getDetailChargerHistory = (params: { id: number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/charging-history/${params.id}`);
};

export const getPrefectureChargerHistory = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/prefecture`);
};
export const getListCityByPrefecture = (params: { prefecture_code: number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/prefecture/city/`, { params });
};

export const deleteSection = (id: string | number) => {
    return getAxiosClientWithToken().delete(`admin/charging-history/${id}`);
};

export const getChartData = (params: { charger_id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/charging-history/data-chart`, { params });
};

export const getDetailBookingCancel = (params: { id: number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/booking/${params.id}`);
};

export const getStatisticHistory = (params: ListStatisticHistoryRequest) => {
    return getAxiosClientWithToken().get('admin/charging-history/statistic', { params });
};


