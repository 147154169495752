import React, { useCallback, useEffect, useState } from 'react';
import Breadcrumbs, { Breadcrumb } from 'components/layouts/Breadcrumb';
import { Button, Col, Form, Row, Image, Table, Layout, Card, Typography, Modal, Select, Input, Spin } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { fetchListPlace, ListPlaceRequest, listPlaceSelector, PlaceData, setStatusPlace } from 'redux/slice/listPlaceSlice';
import { isValidRoles, useRedirectStatusError } from 'lib/utils';
import { Helmet } from 'react-helmet';
import placeImg from 'assets/images/place/ic-add-place.svg';
import { ADMIN, charger_used, charger_operation_status, Constant, Message, SUPER_ADMIN } from 'config/constant';
import { ChargerSelector, fetchChargerOperationStatus, fetchChargerType, ListPlaceData } from 'redux/slice/chargeSlice';
import { selectAuth } from 'redux/slice/authSlice';
import ModalConfirm from './ModalConfirm';
import fastCharger from 'assets/images/place/ic-fast-charger.svg';
import normalCharger100V from 'assets/images/place/ic-normal-charger-2.svg';
import normalCharger200V from 'assets/images/place/ic-fast-charger.svg';
import outlet200V from 'assets/images/place/ic-outlet-2.svg';
import outlet100V from 'assets/images/place/ic-outlet.svg';
import { fetchPlaceStatistic, fetchPlaceType, placeSelector, resetUpdateState } from 'redux/slice/placeSlice';
import { ChargerHistorySelector, getListCityByPrefectureData, getPrefectureData } from 'redux/slice/ChargerHistorySlice';

const breadcrumbs: Breadcrumb[] = [
    {
        name: '施設管理',
        url: '/places',
    },
];

const { Column } = Table;
const { Title } = Typography;
const { Option } = Select;

function Place() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { role } = useAppSelector(selectAuth);
    const { loading, places, pagination, status } = useAppSelector(listPlaceSelector);
    const { status: statusAction, openPlace, stopPlace } = useAppSelector(placeSelector);
    const {
        placeStatistic: { data: statistic },
        masterPlaceType: { data: placeType },
    } = useAppSelector(placeSelector);

    const {
        masterChargerType: { data: chargerType },
    } = useAppSelector(ChargerSelector);

    const [searchParams, setSearchParams] = useSearchParams();
    const { prefecture, cityByPrefecture } = useAppSelector(ChargerHistorySelector);
    const dispatch = useAppDispatch();
    const [visible, setVisible] = useState(false);
    const [keyword, setKeyword] = useState<ListPlaceRequest>();

    const prefectureCode = Form.useWatch('prefecture_code', form);
    const cityCode = Form.useWatch('city_code', form);
    const placeTypeId = Form.useWatch('place_type_id', form);
    const chargerTypeId = Form.useWatch('charger_type_id', form);
    const chargerOperationStatus = Form.useWatch('charger_operation_status', form);
    const chargerUse = Form.useWatch('charger_use', form);
    const placeCode = Form.useWatch('place_code', form);
    const chargerCode = Form.useWatch('charger_code', form);
    const placeName = Form.useWatch('place_name', form);

    const prefectureCodeSearch = searchParams.get('prefecture_code');
    const cityCodeSearch = searchParams.getAll('city_code');
    const placeTypeIdSearch = searchParams.getAll('place_type_id');
    const chargerTypeIdSearch = searchParams.getAll('charger_type_id');
    const chargerOperationStatusSearch = searchParams.get('charger_operation_status');
    const chargerUseSearch = searchParams.get('charger_use');
    const placeCodeSearch = searchParams.get('place_code');
    const chargerCodeSearch = searchParams.get('charger_code');
    const placeNameSearch = searchParams.get('place_name');
    const pageSearch = searchParams.get('page') ?? 1;

    const onSearch = () => {
        setVisible(false);
        setSearchParams({
            prefecture_code: prefectureCode ?? '',
            city_code: cityCode ?? '',
            place_type_id: placeTypeId ?? '',
            charger_type_id: chargerTypeId ?? '',
            charger_operation_status: chargerOperationStatus ?? '',
            charger_use: chargerUse ?? '',
            place_code: placeCode ?? '',
            charger_code: chargerCode ?? '',
            place_name: placeName ?? '',
            page: Constant.DEFAULT_PAGE.toString(),
        });
    };

    const onCancel = () => {
        setVisible(false);
    };

    const onClearSearch = () => {
        form.resetFields();
    };

    const onChangePage = (page: number) => {
        setSearchParams({
            prefecture_code: prefectureCodeSearch ?? '',
            city_code: cityCodeSearch ?? '',
            place_type_id: placeTypeIdSearch ?? '',
            charger_type_id: chargerTypeIdSearch ?? '',
            charger_operation_status: chargerOperationStatusSearch ?? '',
            charger_use: chargerUseSearch ?? '',
            place_code: placeCodeSearch ?? '',
            charger_code: chargerCodeSearch ?? '',
            place_name: placeNameSearch ?? '',
            page: page.toString(),
        });
    };

    const openModalSearch = () => {
        setVisible(true);
        form.setFields([
            { name: 'prefecture_code', value: prefectureCodeSearch ?? null },
            { name: 'charger_operation_status', value: chargerOperationStatusSearch ?? null },
            { name: 'charger_use', value: chargerUseSearch ?? null },
            { name: 'place_code', value: placeCodeSearch ?? null },
            { name: 'charger_code', value: chargerCodeSearch ?? null },
            { name: 'place_name', value: placeNameSearch ?? null },
        ]);
        if (!(placeTypeIdSearch.length === 1 && placeTypeIdSearch?.[0] === '')) {
            form.setFields([{ name: 'place_type_id', value: placeTypeIdSearch }]);
        }
        if (!(chargerTypeIdSearch.length === 1 && chargerTypeIdSearch?.[0] === '')) {
            form.setFields([{ name: 'charger_type_id', value: chargerTypeIdSearch }]);
        }

        if (prefectureCodeSearch) {
            if (!(cityCodeSearch.length === 1 && cityCodeSearch?.[0] === '')) {
                form.setFields([{ name: 'city_code', value: cityCodeSearch }]);
            }
        } else {
            form.setFields([{ name: 'city_code', value: [] }]);
        }
    };

    useEffect(() => {
        dispatch(
            fetchListPlace({
                prefecture_code: prefectureCodeSearch,
                city_code: cityCodeSearch,
                place_type_id: placeTypeIdSearch,
                charger_type_id: chargerTypeIdSearch,
                charger_operation_status: chargerOperationStatusSearch,
                charger_use: chargerUseSearch,
                place_code: placeCodeSearch,
                charger_code: chargerCodeSearch,
                place_name: placeNameSearch,
                page: Number(pageSearch),
            } as ListPlaceRequest)
        );
    }, [dispatch, form, searchParams]);

    useEffect(() => {
        setKeyword({
            prefecture_code: prefectureCodeSearch,
            city_code: cityCodeSearch,
            place_type_id: placeTypeIdSearch,
            charger_type_id: chargerTypeIdSearch,
            charger_operation_status: chargerOperationStatusSearch,
            charger_use: chargerUseSearch,
            place_code: placeCodeSearch,
            charger_code: chargerCodeSearch,
            place_name: placeNameSearch,
            page: Number(pageSearch),
        } as ListPlaceRequest);

        form.setFields([
            { name: 'prefecture_code', value: prefectureCodeSearch ?? null },
            { name: 'charger_operation_status', value: chargerOperationStatusSearch ?? null },
            { name: 'charger_use', value: chargerUseSearch ?? null },
            { name: 'place_code', value: placeCodeSearch ?? null },
            { name: 'charger_code', value: chargerCodeSearch ?? null },
            { name: 'place_name', value: placeNameSearch ?? null },
        ]);
        if (!(placeTypeIdSearch.length === 1 && placeTypeIdSearch?.[0] === '')) {
            form.setFields([{ name: 'place_type_id', value: placeTypeIdSearch }]);
        }
        if (!(chargerTypeIdSearch.length === 1 && chargerTypeIdSearch?.[0] === '')) {
            form.setFields([{ name: 'charger_type_id', value: chargerTypeIdSearch }]);
        }
        if (prefectureCodeSearch) {
            if (!(cityCodeSearch.length === 1 && cityCodeSearch?.[0] === '')) {
                form.setFields([{ name: 'city_code', value: cityCodeSearch }]);
            }
        } else {
            form.setFields([{ name: 'city_code', value: [] }]);
        }
    }, [searchParams]);

    const handlePlace = (object: any) => {
        const chunk_size = 4,
            chunks = [];
        for (const cols = Object.entries(object); cols.length; ) {
            // @ts-ignore
            chunks.push(cols.splice(0, chunk_size).reduce((o, [k, v]) => ((o[k] = v), o), {}));
        }
        return chunks;
    };

    const renderBatteryCharger = (index: number) => {
        let arr = Object.values(statistic?.charger?.detail)[index];
        // @ts-ignore
        return <Typography>{arr}</Typography>;
    };

    const renderChargerType = (index: number) => {
        let arr = Object.values(statistic?.charger_type?.detail)[index];
        // @ts-ignore
        return <Typography>{arr}</Typography>;
    };

    const renderDetailPlace = (id: number) => {
        const onDetailPlace = () => navigate(`/places/detail/${id}`);
        return (
            <Button className='text detail-button-place' onClick={onDetailPlace}>
                詳細
            </Button>
        );
    };

    const renderTableActions = (_: any, { id }: ListPlaceData) => {
        const onDetailClick = () => navigate(`/chargers/list-chargers/${id}`, { state: true });
        return (
            <Button className='btn detail-charging-equipment' onClick={onDetailClick}>
                一覧
            </Button>
        );
    };

    const onAddPlace = () => {
        navigate('/places/create');
    };

    useEffect(() => {
        dispatch(fetchPlaceStatistic());
        dispatch(fetchPlaceType());
        dispatch(fetchChargerType());
        dispatch(fetchChargerOperationStatus());
        dispatch(getPrefectureData());
    }, [dispatch]);

    const currentListPlace = useCallback(() => {
        dispatch(fetchListPlace({ ...keyword }));
    }, [dispatch, form, keyword]);

    useEffect(() => {
        if (prefectureCode) {
            dispatch(getListCityByPrefectureData({ prefecture_code: prefectureCode }));
        }
    }, [dispatch, prefectureCode]);

    const renderAddress = (_: any, place: PlaceData) => (
        <span>
            {place?.building_name}
            {place.building_name ? '　' : ''}
            {place?.prefecture} {place?.city} {place?.address}
        </span>
    );
    const renderDestroy = (_: any, place: PlaceData) => {
        return place?.status === Constant.STATUS_OPEN_PLACE ? (
            <ModalConfirm
                place={place}
                title='施設の停止'
                nameBtn={Constant.CHARGER_OPEN}
                message={Message.STOP_PLACE}
                keyword={form.getFieldValue('keyword')}
                page={Number(pageSearch)}
                currentPlace={currentListPlace}
            />
        ) : (
            <ModalConfirm
                place={place}
                title='施設の再開'
                nameBtn={Constant.CHARGER_STOP}
                message={Message.OPEN_PLACE}
                keyword={form.getFieldValue('keyword')}
                page={Number(pageSearch)}
                currentPlace={currentListPlace}
            />
        );
    };

    useRedirectStatusError(status, setStatusPlace());
    useRedirectStatusError(statusAction, resetUpdateState());

    return (
        <Layout className='index-place'>
            <Helmet>
                <title>施設管理</title>
            </Helmet>
            <Spin spinning={loading || openPlace.loading || stopPlace.loading}>
                <Row justify={'space-between'}>
                    <Col>
                        <Breadcrumbs breadcrumb={breadcrumbs} />
                    </Col>
                    <Row>
                        <Col>
                            <Button
                                htmlType='button'
                                className='icon-center-container push-now btn-no-icon'
                                onClick={openModalSearch}
                                style={{ color: '#ffffff', backgroundColor: '#34C2C2', borderColor: '#34C2C2' }}
                            >
                                <span className='text-btn '>フィルター</span>
                            </Button>
                        </Col>
                        {isValidRoles(role, [SUPER_ADMIN]) ? (
                            <Col>
                                <Button
                                    htmlType='button'
                                    className='icon-center-container btn-icon'
                                    onClick={onAddPlace}
                                    style={{ color: '#ffffff', backgroundColor: '#34C2C2', borderColor: '#34C2C2' }}
                                >
                                    <Image className='icon-center' width='20px' src={placeImg} preview={false} />
                                    <span className='text-add '>施設の登録</span>
                                </Button>
                            </Col>
                        ) : null}
                    </Row>
                </Row>

                <Col className=''>
                    <Row className='mgb-30'>
                        <Col xs={24} sm={24} md={24} lg={24} xl={22} xxl={20}>
                            <Card>
                                <Row justify='start'>
                                    <Typography className='card-title'>施設登録状況</Typography>
                                </Row>
                                <Row gutter={[16, 16]} justify='space-between'>
                                    <Col xs={24} sm={24} md={7} lg={5} xl={5} xxl={4}>
                                        <Row className='text-bold'>
                                            <Typography>施設数合計</Typography>
                                        </Row>
                                        <Row justify={'center'}>
                                            <Title style={{ fontSize: 64, textAlign: 'center', height: '100%' }}>
                                                {statistic.place?.total}
                                            </Title>
                                        </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={17} lg={19} xl={19} xxl={20} style={{ marginBottom: '30px' }}>
                                        <Row className='text-bold' style={{ marginLeft: '30px', marginBottom: '20px' }}>
                                            <Typography>施設タイプ別登録数</Typography>
                                        </Row>
                                        <Row style={{ marginLeft: '30px' }}>
                                            {statistic.place?.detail
                                                ? handlePlace(statistic.place?.detail).map((item: any, i) => {
                                                      return (
                                                          <Col
                                                              key={i}
                                                              style={{ marginRight: '15px' }}
                                                              xs={24}
                                                              sm={24}
                                                              md={9}
                                                              lg={5}
                                                              xl={5}
                                                              xxl={5}
                                                          >
                                                              {Object.keys(item).map(function (keyName: string, keyIndex) {
                                                                  return (
                                                                      <Row
                                                                          justify='space-between'
                                                                          style={{
                                                                              borderTop: '1.8px solid #C3C3C3',
                                                                              lineHeight: '40px',
                                                                              borderBottom:
                                                                                  Object.keys(item).pop() === keyName
                                                                                      ? '1.8px solid #C3C3C3'
                                                                                      : '0px',
                                                                          }}
                                                                      >
                                                                          <Typography className='text-type-place '>{keyName}</Typography>
                                                                          <Typography>{item[keyName]}</Typography>
                                                                      </Row>
                                                                  );
                                                              })}
                                                          </Col>
                                                      );
                                                  })
                                                : null}
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row gutter={32}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={13} xxl={10} className='mgb-30'>
                            <Card>
                                <Row justify='start' className='mt-2'>
                                    <Typography className='card-title'>充電器利用状況</Typography>
                                </Row>
                                <Row gutter={[16, 16]} justify='space-between'>
                                    <Col xs={24} sm={24} md={7} lg={6} xl={9} xxl={9}>
                                        <Row className='text-bold '>
                                            <Typography>充電器合計数</Typography>
                                        </Row>
                                        <Row justify={'center'}>
                                            <Title style={{ fontSize: 64 }} className='total-number-charger'>
                                                {statistic.charger?.total}
                                            </Title>
                                        </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={17} lg={18} xl={15} xxl={15}>
                                        <Row className='mgl-15 mgb-25 text-bold '>
                                            <Typography>充電器利用状況</Typography>
                                        </Row>
                                        <Row justify='space-between' className='mgl-15 mgb-25'>
                                            <Col xs={24} sm={9} md={9} lg={9} xl={9} xxl={9}>
                                                <Row>
                                                    <Typography className='booked mgr-8'></Typography>
                                                    <Typography className='text-type-status'>利用中</Typography>
                                                </Row>
                                            </Col>
                                            <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                {statistic?.charger?.detail ? renderBatteryCharger(0) : null}
                                            </Col>
                                        </Row>

                                        <Row className='mgl-15 mgb-25' justify='space-between'>
                                            <Col xs={24} sm={9} md={9} lg={9} xl={9} xxl={9}>
                                                <Row>
                                                    <Typography className='available mgr-8'></Typography>
                                                    <Typography className='text-type-status'>利用可能</Typography>
                                                </Row>
                                            </Col>
                                            <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                {statistic?.charger?.detail ? renderBatteryCharger(1) : null}
                                            </Col>
                                        </Row>

                                        <Row className='mgl-15 mgb-25' justify='space-between'>
                                            <Col xs={24} sm={9} md={9} lg={9} xl={9} xxl={9}>
                                                <Row>
                                                    <Typography className='suspension mgr-8'></Typography>
                                                    <Typography className='text-type-status'>利用停止</Typography>
                                                </Row>
                                            </Col>
                                            <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                {statistic?.charger?.detail ? renderBatteryCharger(2) : null}
                                            </Col>
                                        </Row>

                                        <Row className='mgl-15 mgb-25' justify='space-between'>
                                            <Col xs={24} sm={9} md={9} lg={9} xl={9} xxl={9}>
                                                <Row>
                                                    <Typography className='unavailable mgr-8'></Typography>
                                                    <Typography className='text-type-status'>利用不可</Typography>
                                                </Row>
                                            </Col>
                                            <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                {statistic?.charger?.detail ? renderBatteryCharger(3) : null}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={11} xxl={6} className='mgb-30'>
                            <Card>
                                <Row justify='start' className='mt-2'>
                                    <Typography className='card-title'>充電器運営状況</Typography>
                                </Row>
                                <Row className='mgb-50'></Row>
                                <Row>
                                    <Col xs={24} sm={24} md={24}>
                                        <Row className='mgb-25' justify='space-between'>
                                            <Col xs={24} sm={12} md={12} xxl={12} className='mgt-5'>
                                                <Row>
                                                    <Typography className='booked mgr-8'></Typography>
                                                    <Typography className='text-type-status'>15日以内に使用</Typography>
                                                </Row>
                                            </Col>

                                            <Col xs={24} sm={3} md={4} xxl={2}>
                                                {statistic?.charger_used?.detail?.['15_days_left']}
                                            </Col>
                                        </Row>

                                        <Row className='mgb-25' justify='space-between'>
                                            <Col xs={24} sm={12} md={12} xxl={12} className='mgt-5'>
                                                <Row>
                                                    <Typography className='available mgr-8'></Typography>
                                                    <Typography className='text-type-status'>30日以内に使用</Typography>
                                                </Row>
                                            </Col>
                                            <Col xs={24} sm={3} md={4} xxl={2}>
                                                {statistic?.charger_used?.detail?.['30_days_left']}
                                            </Col>
                                        </Row>

                                        <Row className='mgb-25' justify='space-between'>
                                            <Col xs={24} sm={12} md={12} xxl={12} className='mgt-5'>
                                                <Row>
                                                    <Typography className='suspension mgr-8'></Typography>
                                                    <Typography className='text-type-status'>60日以内に使用</Typography>
                                                </Row>
                                            </Col>
                                            <Col xs={24} sm={3} md={4} xxl={2}>
                                                {statistic?.charger_used?.detail?.['60_days_left']}
                                            </Col>
                                        </Row>

                                        <Row className='mgb-25' justify='space-between'>
                                            <Col xs={24} sm={12} md={12} xxl={12} className='mgt-5'>
                                                <Row>
                                                    <Typography className='unavailable mgr-8'></Typography>
                                                    <Typography className='text-type-status'>半年以上未使用</Typography>
                                                </Row>
                                            </Col>
                                            <Col xs={24} sm={3} md={4} xxl={2}>
                                                {statistic?.charger_used?.detail?.['6_months_not_use']}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={13} xxl={8} className='mgb-30'>
                            <Card>
                                <Row justify='start' className='mt-2'>
                                    <Typography className='card-title'>充電器タイプ</Typography>
                                </Row>
                                <Row className='mgb-50'></Row>
                                <Row justify='space-between'>
                                    <Col xs={24} sm={24} md={12}>
                                        <Row className='mgb-25' justify='space-between'>
                                            <Col sm={7} xxl={4}>
                                                <Image width='35px' src={fastCharger} preview={false} />
                                            </Col>
                                            <Col sm={13} xxl={13} className='charger-type-50 line-height-status'>
                                                <Row>
                                                    <Typography>急速充電器</Typography>
                                                </Row>
                                            </Col>
                                            <Col sm={4} xxl={4} className='line-height-status mr-1'>
                                                {statistic?.charger_type?.detail ? renderChargerType(0) : null}
                                            </Col>
                                        </Row>

                                        <Row className='mgb-25' justify='space-between'>
                                            <Col sm={7} xxl={4}>
                                                <Image width='35px' src={normalCharger200V} preview={false} />
                                            </Col>
                                            <Col sm={13} xxl={13} className='charger-type-50 line-height-status'>
                                                <Row>
                                                    <Typography>200V普通充電器</Typography>
                                                </Row>
                                            </Col>
                                            <Col sm={4} xxl={4} className='line-height-status mr-1'>
                                                {statistic?.charger_type?.detail ? renderChargerType(2) : null}
                                            </Col>
                                        </Row>

                                        <Row className='mgb-25' justify='space-between'>
                                            <Col sm={7} xxl={4}>
                                                <Image width='35px' src={outlet200V} preview={false} />
                                            </Col>
                                            <Col sm={13} xxl={13} className='charger-type-50 line-height-status'>
                                                <Row>
                                                    <Typography>200V コンセント</Typography>
                                                </Row>
                                            </Col>
                                            <Col sm={4} xxl={4} className='line-height-status mr-1'>
                                                {statistic?.charger_type?.detail ? renderChargerType(3) : null}
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xs={24} sm={24} md={12}>
                                        <Row className='mgb-25' justify='space-between'>
                                            <Col sm={7} xxl={4}>
                                                <Image width='35px' src={normalCharger100V} preview={false} />
                                            </Col>
                                            <Col sm={13} xxl={13} className='charger-type-50 line-height-status'>
                                                <Typography>100V普通充電器</Typography>
                                            </Col>
                                            <Col sm={4} xxl={2} className='line-height-status '>
                                                {statistic?.charger_type?.detail ? renderChargerType(1) : null}
                                            </Col>
                                        </Row>

                                        <Row className='mgb-25' justify='space-between'>
                                            <Col sm={7} xxl={4}>
                                                <Image width='35px' src={outlet100V} preview={false} />
                                            </Col>
                                            <Col sm={13} xxl={13} className='charger-type-50 line-height-status'>
                                                <Typography>100V コンセント</Typography>
                                            </Col>
                                            <Col sm={4} xxl={2} className='line-height-status '>
                                                {statistic?.charger_type?.detail ? renderChargerType(4) : null}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Card className="mgt-5">
                    <Table
                        dataSource={places}
                        className={pagination?.total > pagination?.perPage ? 'text-center mt-4 mb-1' : 'text-center mt-4 mb-4'}
                        pagination={
                            pagination?.total > pagination?.perPage
                                ? {
                                    total: pagination?.total,
                                    current: Number(pageSearch),
                                    pageSize: pagination?.perPage,
                                    position: ['bottomRight'],
                                    onChange: onChangePage,
                                    showSizeChanger: false,
                                }
                                : false
                        }
                        scroll={{ x: 960 }}
                        rowKey='id'
                    >
                        <Column title='No' dataIndex='id' key='id' width='5%' />
                        <Column title='施設ID' dataIndex='code' key='code' width='8%' />
                        <Column title='施設タイプ' dataIndex='place_type_name' key='place_type_name' width='10%' />
                        <Column title='施設名' dataIndex='name' key='name' width='25%' />
                        <Column title='住所' dataIndex='address' key='id' width='25%' render={renderAddress} />
                        <Column title='詳細' dataIndex='id' key='id' width='5%' render={renderDetailPlace} />
                        <Column title='一覧' dataIndex='id' key='id' render={renderTableActions} width='5%' />
                        {isValidRoles(role, [SUPER_ADMIN, ADMIN]) ? (
                            <Column title='営業状態' dataIndex='id' key='id' render={renderDestroy} width='3%' />
                        ) : null}
                    </Table>
                </Card>
                <Modal centered visible={visible} footer={null} width={700} destroyOnClose={true} onCancel={onCancel}>
                    <Form
                        className='mt-0'
                        labelWrap
                        form={form}
                        labelCol={{ span: 24 }}
                        requiredMark={true}
                        onFinish={onSearch}
                        colon={false}
                    >
                        <Row>
                            <Col span={11}>
                                <Form.Item name='prefecture_code' label='都道府県'>
                                    <Select dropdownStyle={{ zIndex: 2000 }} showArrow>
                                        {prefecture?.data?.map(({ code, name }) => {
                                            return (
                                                <Option key={code} value={code}>
                                                    {name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11} offset={2}>
                                <Form.Item name='city_code' label='市町村'>
                                    <Select disabled={!prefectureCode} dropdownStyle={{ zIndex: 2000 }} mode='multiple' showArrow>
                                        {cityByPrefecture?.data?.map((data, index) => (
                                            <Option key={`city-${index}`} value={data?.city_code}>
                                                {data?.city_name}{' '}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={11}>
                                <Form.Item name='place_type_id' label='施設タイプ'>
                                    <Select dropdownStyle={{ zIndex: 2000 }} mode='multiple' showArrow>
                                        {placeType.map(({ id, name }) => {
                                            return (
                                                <Option key={`place_type_id_${id}`} value={id.toString()}>
                                                    {name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11} offset={2}>
                                <Form.Item name='charger_type_id' label='充電器タイプ'>
                                    <Select dropdownStyle={{ zIndex: 2000 }} mode='multiple' showArrow>
                                        {chargerType.map(({ id, name }) => {
                                            return (
                                                <Option key={`charger_type_id_${id}`} value={id.toString()}>
                                                    {name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={11}>
                                <Form.Item name='charger_operation_status' label='充電器利用状況'>
                                    <Select dropdownStyle={{ zIndex: 2000 }} showArrow>
                                        {charger_operation_status.map(({ id, name }) => {
                                            return (
                                                <Option key={`charger_operation_status_${id}`} value={id.toString()}>
                                                    {name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11} offset={2}>
                                <Form.Item name='charger_use' label='充電器運営状況'>
                                    <Select dropdownStyle={{ zIndex: 2000 }} showArrow>
                                        {charger_used.map(({ id, name }) => {
                                            return (
                                                <Option key={`charger_use_${id}`} value={id.toString()}>
                                                    {name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item name='place_code' label='施設ID'>
                            <Input />
                        </Form.Item>

                        <Form.Item name='charger_code' label='充電器ID'>
                            <Input />
                        </Form.Item>

                        <Form.Item name='place_name' label='施設名'>
                            <Input />
                        </Form.Item>

                        <Row justify='center'>
                            <Col span={10}>
                                <Row justify='space-between'>
                                    <Button
                                        onClick={onCancel}
                                        className='icon-center-container '
                                        style={{
                                            width: '30%',
                                            color: 'red',
                                            justifyContent: 'center',
                                            backgroundColor: '#FFF1F0',
                                            borderColor: 'red',
                                            paddingRight: '30px',
                                            paddingLeft: '30px',
                                        }}
                                    >
                                        戻る
                                    </Button>
                                    <Button
                                        onClick={onClearSearch}
                                        className='icon-center-container'
                                        style={{
                                            width: '30%',
                                            color: '#2F9B4B',
                                            justifyContent: 'center',
                                            backgroundColor: '#ffffff',
                                            borderColor: '#34c2c2',
                                        }}
                                    >
                                        クリア
                                    </Button>
                                    <Button
                                        htmlType='submit'
                                        className='icon-center-container btn-button-modal'
                                        style={{
                                            width: '30%',
                                            color: '#2F9B4B',
                                            justifyContent: 'center',
                                            backgroundColor: '#E0F4E4',
                                            borderColor: '#2F9B4B',
                                        }}
                                    >
                                        実行
                                    </Button>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </Spin>
        </Layout>
    );
}

export default Place;
