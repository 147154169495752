import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { addCreditCard, getDetailCreditCard, updateCreditCard } from "api/creditCard";
import { Constant } from 'config/constant';
import { createMyCharger } from './chargeSlice';
import { getErrorMessage } from 'api';
import { CreateCreditCard, CreditCardValidate} from 'types';


export type CreditCardState = {
    status: number;
    createCreditCard: CreateCreditCard[];
    register: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string | CreditCardValidate;
    };
    detailCreditCard: {
        data: CreateCreditCard;
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string | CreditCardValidate;
    };
    updateCreditCard: {
        success: boolean;
        error: boolean;
        messages: string | CreditCardValidate;
        loading: boolean;
    };
};

export const createCreditCard = createAsyncThunk(
    'admin/credit-card/create',
    async (params: CreateCreditCard, { dispatch, rejectWithValue }) => {
        try {
            const response = await addCreditCard(params);
            const { data, success } = response.data;

            if (success) {
                dispatch(setNewCreditCard({ data }));
                return true;
            }
        } catch (error: any) {
            dispatch(setNewCreditCard(error));
            return rejectWithValue(error);
        }
    }
);

export const editCreditCard = createAsyncThunk(
    '/admin/credit-card/update',
    async (params: CreateCreditCard , { dispatch, rejectWithValue }) => {
        try {
            let response = await updateCreditCard(params);
            const { data = {}, success } = response.data;
            if (success) {
                dispatch(setEditCreditCard(data));
                return true;
            }
        } catch (error: any) {
            dispatch(setEditCreditCard(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const detailCreditCard = createAsyncThunk('admin/credit-card/detail', async (_, { dispatch, rejectWithValue }) => {
    try {
        const response = await getDetailCreditCard();
        const { data, success } = response.data;
        if (success) {
            dispatch(setDetailCreditCard({ data }));
            return true;
        }
    } catch (error: any) {
        return rejectWithValue(error);
    }
});

export const creditCardSlice = createSlice({
    name: 'creditCard',
    initialState: {
        status: Constant.DEFAULT_STATUS,
        createCreditCard: [],
        register: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
        detailCreditCard: {
            data: {} as CreateCreditCard,
            loading: false,
            success: false,
            error: false,
            message: '',
        },
        updateCreditCard: {
            success: false,
            error: false,
            messages: '',
            loading: false,
        },
    } as CreditCardState,
    reducers: {
        resetRegisterState: (state: CreditCardState) => {
            state.status = Constant.DEFAULT_STATUS;
            state.register = {
                success: false,
                message: '',
                loading: false,
                error: false,
            };
        },
        resetUpdateState: (state: CreditCardState) => {
            state.status = Constant.DEFAULT_STATUS;
            state.updateCreditCard = {
                loading: false,
                success: false,
                error: false,
                messages: '',
            };
        },
        setNewCreditCard: (state: CreditCardState, action) => {
            state.createCreditCard = action?.payload ?? [];
            state.status = action?.payload?.response?.status;
        },
        setDetailCreditCard: (state: CreditCardState, { payload }) => {
            state.detailCreditCard.data = payload?.data;
            state.detailCreditCard.success = payload?.success;
            state.detailCreditCard.message = payload?.message;
            state.status = payload?.response?.status;
        },
        setEditCreditCard: (state: CreditCardState , action) => {
            state.createCreditCard = action?.payload ?? [];
            state.status = action?.payload?.response?.status;
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(createCreditCard.pending, (state: CreditCardState) => {
                state.register.loading = true;
            })
            .addCase(createMyCharger.rejected, (state: CreditCardState, { payload }) => {
                state.register.loading = false;
                state.register.success = false;
                state.register.error = true;
                state.register.message = payload as string | CreditCardValidate;
            })
            .addCase(createMyCharger.fulfilled, (state: CreditCardState) => {
                state.register.loading = false;
                state.register.success = true;
                state.register.error = false;
            })
            .addCase(detailCreditCard.pending, (state: CreditCardState) => {
                state.detailCreditCard.loading = true;
            })
            .addCase(detailCreditCard.rejected, (state: CreditCardState, { payload }) => {
                state.detailCreditCard.loading = false;
                state.detailCreditCard.success = false;
                state.detailCreditCard.error = true;
                state.detailCreditCard.message = payload as string | CreditCardValidate;
            })
            .addCase(detailCreditCard.fulfilled, (state: CreditCardState) => {
                state.detailCreditCard.loading = false;
                state.detailCreditCard.success = true;
                state.detailCreditCard.error = false;
            })
          .addCase(editCreditCard.pending, (state: CreditCardState) => {
              state.updateCreditCard.loading = true;
          })
          .addCase(editCreditCard.rejected, (state: CreditCardState, { payload }) => {
              state.updateCreditCard.loading = false;
              state.updateCreditCard.success = false;
              state.updateCreditCard.error = true;
              state.updateCreditCard.messages = payload as string | CreditCardValidate;
          })
          .addCase(editCreditCard.fulfilled, (state: CreditCardState) => {
              state.updateCreditCard.loading = false;
              state.updateCreditCard.success = true;
              state.updateCreditCard.error = false;
          });
    },
});

export const creditCardSelector = (state: RootState) => state.creditCard;
export const { resetRegisterState, resetUpdateState, setNewCreditCard, setDetailCreditCard, setEditCreditCard } = creditCardSlice.actions;
