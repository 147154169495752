import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    createInvoice,
    getDetailInvoice,
    getExportInvoice,
    getListInvoice,
    getPreviewInvoice,
    getStatisticInvoice
} from 'api/invoice';
import { RootState } from 'redux/store';
import { Constant } from 'config/constant';
import { downloadExcel } from 'lib/utils';

export type CreateInvoiceParams = {
    number: string;
    billing_time_start: string;
    billing_time_end: string;
    destination: string;
    subject: string;
    payment_time: string;
    person_in_charge: string;
    phone: string;
    email: string;
    fax?: string;
    note: string;
};

export type ListInvoiceRequest = {
    page?: number | string;
    start_time?: string | null;
    end_time?: string | null;
};

export type DataCreateInvoice = {
    number: string;
    billing_time_start: string;
    billing_time_end: string;
    billing_time: any;
    destination: string;
    subject: string;
    payment_time: string;
    person_in_charge: string;
    phone: string;
    email: string;
    fax?: string;
    note: string;
};

export type DataInvoice = {
    id: number;
    number: string;
    billing_time_start: string;
    billing_time_end: string;
    billing_time: string;
    created_at: string;
    destination: string;
    subject: string;
    payment_time: string;
    person_in_charge: string;
    phone: string;
    email: string;
    fax?: string;
    note: string;
    amount: string | number;
    tax: string | number;
    total_amount: string | number;
};

export type StatisticInvoice = {
    total_amount: number;
    total_charging: number;
    total_electric: number;
    total_power: number;
    total_time: number;
};
export type DataPreviewInvoice = {
    id: string | number;
    number: string;
    billing_time_start: string;
    billing_time_end: string;
    created_at: string;
    destination: string;
    subject: string;
    payment_time: string;
    person_in_charge: string;
    phone: string;
    email: string;
    fax?: string;
    note: string;
    amount: string | number;
    tax: string | number;
    total_amount: string | number;
};

export type DataStatisticInvoice = { detail: StatisticInvoice, sub_date: string };

type Pagination = {
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
};

export type InvoiceState = {
    status: number;
    createInvoice: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string;
    };
    previewInvoice: {
        loading: boolean;
        success: boolean;
        error: boolean;
        data: DataCreateInvoice;
        message: string;
    };
    listInvoice: {
        loading: boolean;
        success: boolean;
        error: boolean;
        data: DataInvoice[];
        message: string;
        pagination: Pagination;
    };
    statisticInvoice: {
        loading: boolean;
        success: boolean;
        error: boolean;
        data: DataStatisticInvoice;
        message: string;
    };
    previewInInvoice: {
        loading: boolean;
        success: boolean;
        error: boolean;
        data: DataPreviewInvoice;
        message: string;
    };
    detailInvoice: {
        loading: boolean;
        success: boolean;
        error: boolean;
        data: DataPreviewInvoice;
        message: string;
    };
    exportInvoice: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string;
    };
};

export const storeInvoice = createAsyncThunk('admin/invoice/create', async (params: CreateInvoiceParams, { dispatch, rejectWithValue }) => {
    try {
        let response = await createInvoice(params);
        dispatch(setCreateInvoice(response.data));
    } catch (error: any) {
        dispatch(setCreateInvoice(error));
        return rejectWithValue(error);
    }
});

export const fetchPreviewInvoice = createAsyncThunk(
    'admin/invoice/preview-in',
    async (params: { billing_time_start: string; billing_time_end: string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await getPreviewInvoice(params);

            dispatch(setDataPreviewInvoice(response.data));
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const previewInvoice = createAsyncThunk('invoice/preview', async (params: DataCreateInvoice, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setPreviewInvoice(params));
    } catch (error: any) {
        return rejectWithValue(error);
    }
});

export const fetchDetailInvoice = createAsyncThunk(
    'admin/invoice/:id',
    async (params: { id?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            const response = await getDetailInvoice(params);

            dispatch(setDetailInvoice(response.data));
        } catch (error: any) {
            dispatch(setDetailInvoice(error));
            return rejectWithValue(error);
        }
    }
);

export const fetchExportInvoice = createAsyncThunk(
    'admin/invoice/:id/export',
    async (params: { id?: number | string }, { dispatch, rejectWithValue }) => {
        try {
            const response = await getExportInvoice(params);
            dispatch(setExportInvoice(response.data));
            downloadExcel(response.data);
        } catch (error: any) {
            dispatch(setExportInvoice(error));
            return rejectWithValue(error);
        }
    }
);

export const fetchListInvoice = createAsyncThunk('admin/invoice', async (params: ListInvoiceRequest, { dispatch, rejectWithValue }) => {
    try {
        const response = await getListInvoice(params);
        const {
            data = [],
            total = 0,
            per_page: perPage = Constant.PAGE_SIZE_NEW,
            current_page: currentPage = Constant.DEFAULT_PAGE,
            last_page: lastPage = Constant.DEFAULT_PAGE,
        } = response.data;

        dispatch(setListInvoice({ invoice: data, pagination: { total, perPage, currentPage, lastPage } }));
    } catch (error: any) {
        return rejectWithValue(error);
    }
});

export const fetchStatisticInvoice = createAsyncThunk('admin/invoice/statistic', async (params: ListInvoiceRequest, { dispatch, rejectWithValue }) => {
    try {
        const response = await getStatisticInvoice(params);

        dispatch(setStatisticInvoice(response.data));
    } catch (error: any) {
        dispatch(setStatisticInvoice(error));
        return rejectWithValue(error);
    }
});

export const invoiceSlice = createSlice({
    name: 'invoiceSlice',
    initialState: {
        status: Constant.DEFAULT_STATUS,
        createInvoice: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
        previewInvoice: {
            loading: false,
            success: false,
            error: false,
            data: {} as DataCreateInvoice,
            message: '',
        },
        listInvoice: {
            loading: false,
            success: false,
            error: false,
            data: [] as DataInvoice[],
            message: '',
            pagination: {} as Pagination,
        },
        statisticInvoice: {
            loading: false,
            success: false,
            error: false,
            data: {} as DataStatisticInvoice,
            message: '',
        },
        previewInInvoice: {
            loading: false,
            success: false,
            error: false,
            data: {} as DataPreviewInvoice,
            message: '',
        },
        detailInvoice: {
            loading: false,
            success: false,
            error: false,
            data: {} as DataPreviewInvoice,
            message: '',
        },
        exportInvoice: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
    } as InvoiceState,
    reducers: {
        setCreateInvoice: (state: InvoiceState, { payload }) => {
            state.status = payload?.status;
            state.createInvoice.message = payload?.response?.data?.message;
            state.createInvoice.success = payload?.response?.data?.success;
        },
        setListInvoice: (state: InvoiceState, { payload }) => {
            const { invoice, pagination } = payload;
            state.listInvoice.data = invoice;
            state.listInvoice.pagination = pagination;
        },
        setStatisticInvoice: (state: InvoiceState, { payload }) => {
            state.statisticInvoice.data = payload?.data;
            state.status = payload?.response?.status;
        },
        setPreviewInvoice: (state: InvoiceState, { payload }) => {
            state.previewInvoice.data = payload;
        },
        setDataPreviewInvoice: (state: InvoiceState, { payload }) => {
            state.previewInInvoice.data = payload.data;
            state.status = payload.status;
        },
        setDetailInvoice: (state: InvoiceState, { payload }) => {
            state.detailInvoice.data = payload?.data;
            state.status = payload?.response?.status;
        },
        setExportInvoice: (state: InvoiceState, { payload }) => {
            state.status = payload?.response?.status;
        },
        resetPreviewInvoice: (state: InvoiceState) => {
            state.previewInvoice.data = {} as DataCreateInvoice;
            state.previewInvoice.loading = false;
            state.previewInvoice.success = false;
            state.previewInvoice.error = false;
            state.previewInvoice.message = '';
            state.status = Constant.DEFAULT_STATUS;
        },
        resetDataPreviewInvoice: (state: InvoiceState) => {
            state.previewInInvoice.data = {} as DataPreviewInvoice;
            state.previewInInvoice.loading = false;
            state.previewInInvoice.success = false;
            state.previewInInvoice.error = false;
            state.previewInInvoice.message = '';
            state.status = Constant.DEFAULT_STATUS;
        },
        resetSuccessInvoice: (state: InvoiceState) => {
            state.previewInvoice.loading = false;
            state.previewInvoice.success = false;
            state.previewInvoice.error = false;
            state.status = Constant.DEFAULT_STATUS;
        },
        resetStoreInvoice: (state: InvoiceState) => {
            state.createInvoice.loading = false;
            state.createInvoice.success = false;
            state.createInvoice.error = false;
            state.createInvoice.message = '';
            state.status = Constant.DEFAULT_STATUS;
        },
        resetStatusInvoice: (state: InvoiceState) => {
            state.status = Constant.DEFAULT_STATUS;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(storeInvoice.pending, (state: InvoiceState) => {
                state.createInvoice.loading = true;
            })
            .addCase(storeInvoice.rejected, (state: InvoiceState) => {
                state.createInvoice.loading = false;
                state.createInvoice.success = false;
                state.createInvoice.error = true;
            })
            .addCase(storeInvoice.fulfilled, (state: InvoiceState) => {
                state.createInvoice.loading = false;
                state.createInvoice.success = true;
                state.createInvoice.error = false;
            })
            .addCase(previewInvoice.pending, (state: InvoiceState) => {
                state.previewInvoice.loading = true;
            })
            .addCase(previewInvoice.rejected, (state: InvoiceState) => {
                state.previewInvoice.loading = false;
                state.previewInvoice.success = false;
                state.previewInvoice.error = true;
            })
            .addCase(previewInvoice.fulfilled, (state: InvoiceState) => {
                state.previewInvoice.loading = false;
                state.previewInvoice.success = true;
                state.previewInvoice.error = false;
            })
            .addCase(fetchPreviewInvoice.pending, (state: InvoiceState) => {
                state.previewInInvoice.loading = true;
            })
            .addCase(fetchPreviewInvoice.rejected, (state: InvoiceState) => {
                state.previewInInvoice.loading = false;
                state.previewInInvoice.success = false;
                state.previewInInvoice.error = true;
            })
            .addCase(fetchPreviewInvoice.fulfilled, (state: InvoiceState) => {
                state.previewInInvoice.loading = false;
                state.previewInInvoice.success = true;
                state.previewInInvoice.error = false;
            })
            .addCase(fetchDetailInvoice.pending, (state: InvoiceState) => {
                state.detailInvoice.loading = true;
            })
            .addCase(fetchDetailInvoice.rejected, (state: InvoiceState) => {
                state.detailInvoice.loading = false;
                state.detailInvoice.success = false;
                state.detailInvoice.error = true;
            })
            .addCase(fetchDetailInvoice.fulfilled, (state: InvoiceState) => {
                state.detailInvoice.loading = false;
                state.detailInvoice.success = true;
                state.detailInvoice.error = false;
            })
            .addCase(fetchListInvoice.pending, (state: InvoiceState) => {
                state.listInvoice.loading = true;
            })
            .addCase(fetchListInvoice.rejected, (state: InvoiceState) => {
                state.listInvoice.loading = false;
                state.listInvoice.success = false;
                state.listInvoice.error = true;
            })
            .addCase(fetchListInvoice.fulfilled, (state: InvoiceState) => {
                state.listInvoice.loading = false;
                state.listInvoice.success = true;
                state.listInvoice.error = false;
            })
            .addCase(fetchExportInvoice.pending, (state: InvoiceState) => {
                state.exportInvoice.loading = true;
            })
            .addCase(fetchExportInvoice.rejected, (state: InvoiceState) => {
                state.exportInvoice.loading = false;
                state.exportInvoice.success = false;
                state.exportInvoice.error = true;
            })
            .addCase(fetchExportInvoice.fulfilled, (state: InvoiceState) => {
                state.exportInvoice.loading = false;
                state.exportInvoice.success = true;
                state.exportInvoice.error = false;
            })
            .addCase(fetchStatisticInvoice.pending, (state: InvoiceState) => {
                state.statisticInvoice.loading = true;
            })
            .addCase(fetchStatisticInvoice.rejected, (state: InvoiceState) => {
                state.statisticInvoice.loading = false;
                state.statisticInvoice.success = false;
                state.statisticInvoice.error = true;
            })
            .addCase(fetchStatisticInvoice.fulfilled, (state: InvoiceState) => {
                state.statisticInvoice.loading = false;
                state.statisticInvoice.success = true;
                state.statisticInvoice.error = false;
            });
    },
});

export const InvoiceSelector = (state: RootState) => state.invoice;
export const {
    setExportInvoice,
    setCreateInvoice,
    setDetailInvoice,
    setPreviewInvoice,
    setDataPreviewInvoice,
    resetPreviewInvoice,
    resetStatusInvoice,
    resetStoreInvoice,
    resetSuccessInvoice,
    resetDataPreviewInvoice,
    setListInvoice,
    setStatisticInvoice
} = invoiceSlice.actions;
