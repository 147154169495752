import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { getListPlace } from 'api/place';
import { Constant } from 'config/constant';

export type ListPlaceState = {
    error: boolean;
    loading: boolean;
    success: boolean;
    places: PlaceData[];
    pagination: Pagination;
    status: number;
};
export type PlaceState = {
    error: boolean;
    loading: boolean;
    success: boolean;
    places: PlaceData[];
};

type Pagination = {
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
};

export type PlaceData = {
    id: number;
    code: string;
    name: string;
    address: string;
    building_name: string;
    prefecture: string;
    city: string;
    place_type_id: number;
    place_type_name: string;
    status: number;
};

export type PlaceType = {
    id: number;
    name: string;
};

export type ListPlaceRequest = {
    page?: number;
    keyword?: string;
    prefecture_code?: number | string;
    city_code?: number[] | string[];
    place_type_id?: number[] | string[];
    charger_type_id?: number[] | string[];
    charger_operation_status?: number | string;
    place_code?: string ;
    charger_code?: string;
    place_name?: string;
    charger_use?: number | string ;
};

export type idPlace = {
    id?: number;
};

export const fetchListPlace = createAsyncThunk('admin/places', async (params: ListPlaceRequest, { dispatch }) => {
    try {
        const response = await getListPlace(params);
        const {
            data = [],
            total = 0,
            per_page: perPage = Constant.PAGE_SIZE,
            current_page: currentPage = Constant.DEFAULT_PAGE,
            last_page: lastPage = Constant.DEFAULT_PAGE,
            success,
        } = response.data;

        if (success) {
            dispatch(setListPlace({ places: data, pagination: { total, perPage, currentPage, lastPage } }));
            return true;
        }
    } catch (error: any) {
        dispatch(setListPlace(error));
    }
    return false;
});

export const listPlaceSlice = createSlice({
    name: 'listPlace',
    initialState: {
        error: false,
        loading: false,
        success: false,
        places: [],
        pagination: {
            total: 0,
            perPage: Constant.PAGE_SIZE,
            currentPage: Constant.DEFAULT_PAGE,
            lastPage: Constant.DEFAULT_PAGE,
        },
        status: Constant.DEFAULT_STATUS,
    } as ListPlaceState,
    reducers: {
        setStatusPlace: (state: ListPlaceState) => {
            state.status = Constant.DEFAULT_STATUS;
        },
        setListPlace: (state: ListPlaceState, { payload }) => {
            const { places, pagination } = payload;
            state.places = places;
            state.pagination = pagination;
            state.status = payload?.response?.status;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchListPlace.pending, (state: ListPlaceState) => {
                state.loading = true;
            })
            .addCase(fetchListPlace.rejected, (state: ListPlaceState) => {
                state.loading = false;
                state.success = false;
                state.error = true;
            })
            .addCase(fetchListPlace.fulfilled, (state: ListPlaceState) => {
                state.loading = false;
                state.success = true;
                state.error = false;
            });
    },
});

export const listPlaceSelector = (state: RootState) => state.listPlaces;
export const { setListPlace, setStatusPlace } = listPlaceSlice.actions;
